.app-search-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.search-engine-container {
    position: absolute;
    top: 10%; /* Keeps the container near the top */
    left: 50%; /* Centers it horizontally */
    transform: translateX(-50%); /* Adjusts the container to be centered */

    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Change to flex-start for proper alignment */
    align-items: center; /* Centers content horizontally */

    min-width: 100vw;
    max-height: calc(100vh - 10%); /* Ensures it does not go below footer */
    overflow: hidden; /* Prevents overflow of container */
}

.search-engine-container .results-container {
    min-width: 80vw;
    max-height: 65vh; /* Set a max height for scrolling */
    overflow-y: auto; /* Allows vertical scrolling */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.search-engine-container .results-container .result-container {
    max-width: 40vw;
}
