.app-dashboard{
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.powered-by-section{
    position: absolute;
    right: 100px;
    display: flex;
    align-items: baseline;
}

.logo-footer {
    width: 60px;
    height: auto;
}

.logo-header {
    width: 100px;
    height: auto;
}

.research-form-container {
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}